<template>
    <div class="global-content">
        <div class="block global-margin-bottom global-shadow">
            <div><i class="fa fa-building global-title-fa-big"></i><span class="global-title">设备控制</span></div>
            <van-divider />
            <div class="menu">
                <van-row>
                    <div @click="goUrl('program')">
                        <van-col span="12">
                            <i class="fa fa-support"></i>
                            <span>节目控制</span>
                        </van-col>
                    </div>
                    <div @click="goUrl('illumination')">
                        <van-col span="12">
                            <i class="fa fa-lightbulb-o fa-big"></i>
                            <span>照明控制</span>
                        </van-col>
                    </div>
                </van-row>
                <van-row>
                    <div @click="goUrl('picture')">
                        <van-col span="12">
                            <i class="fa fa-picture-o"></i>
                            <span>拍照控制</span>
                        </van-col>
                    </div>
                    <div @click="goUrl('video')">
                        <van-col span="12">
                            <i class="fa fa-video-camera"></i>
                            <span>视频查看</span>
                        </van-col>
                    </div>
                </van-row>
                <!-- <van-row>
                    <div @click="goUrl('chat')">
                        <van-col span="12">
                            <i class="fa fa-microphone"></i>
                            <span>语音对讲</span>
                        </van-col>
                    </div>
                    <van-col span="12">
                    </van-col>
                </van-row> -->
            </div>
        </div>
        <div class="block global-shadow global-margin-bottom ">
            <div><i class="fa fa-history global-title-fa-big"></i><span class="global-title">历史数据</span></div>
            <van-divider />
            <div class="menu">
                <van-row>
                    <div @click="goUrl('warn')">
                        <van-col span="12">
                            <i class="fa fa-warning"></i>
                            <span>告警数据</span>
                        </van-col>
                    </div>
                    <div @click="goUrl('status')">
                        <van-col span="12">
                            <i class="fa fa-info-circle fa-big"></i>
                            <span>设备状态</span>
                        </van-col>
                    </div>
                </van-row>
                <van-row>
                    <div @click="goUrl('weather')">
                        <van-col span="12">
                            <i class="fa fa-cloud"></i>
                            <span>气象数据</span>
                        </van-col>
                    </div>
                    <!-- <div @click="goUrl('car')">
                        <van-col span="12">
                            <i class="fa fa-car"></i>
                            <span>过车数据</span>
                        </van-col>
                    </div> -->
                </van-row>
            </div>
        </div>
        <!-- <div class="block global-shadow global-margin-bottom ">
            <div><i class="fa fa-area-chart global-title-fa-big"></i><span class="global-title">统计信息</span></div>
            <van-divider />
            <div class="menu">
                <van-row>
                    <div @click="goUrl('histogram')">
                        <van-col span="12">
                            <i class="fa fa-bar-chart"></i>
                            <span>柱状图像</span>
                        </van-col>
                    </div>
                    <div @click="goUrl('line')">
                        <van-col span="12">
                            <i class="fa fa-line-chart"></i>
                            <span>折线图像</span>
                        </van-col>
                    </div>
                </van-row>
            </div>
        </div> -->
        <router-view></router-view>
        <tabBar/>
    </div>
</template>

<script>
import tabBar from '@/components/itgs/tabBar.vue'

export default {
    name:'Control',
    components: {
        tabBar
    },
    methods:{
        goUrl(path){
            let visitor = window.localStorage.getItem('visitor');
            if(visitor=='1'){
                this.$toast('当前为游客模式，暂无权限');
                return false
            }
            switch (path) {
                case "program":
                    this.$router.push({path:'/itgs/control/program'})
                    break;
                case "illumination":
                    this.$router.push({path:'/itgs/control/illumination'})
                    break;
                case "picture":
                    this.$router.push({path:'/itgs/control/picture'})
                    break;
                case "video":
                    this.$router.push({path:'/itgs/control/video'})
                    break;
                case "chat":
                    this.$router.push({path:'/itgs/control/chat'})
                    break;
                case "warn":
                    this.$router.push({path:'/itgs/control/warn'})
                    break;
                case "status":
                    this.$router.push({path:'/itgs/control/status'})
                    break;
                case "weather":
                    this.$router.push({path:'/itgs/control/weather'})
                    break;
                case "car":
                    this.$router.push({path:'/itgs/control/car'})
                    break;
                case "histogram":
                    this.$router.push({path:'/itgs/control/histogram'})
                    break;
                case "line":
                    this.$router.push({path:'/itgs/control/line'})
                    break;
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.block{
    padding: 2%;
    background-color: #fff;
    border-radius: 10px;
    & a{color: #656565;}
    .menu{
        text-align: center;
        font-weight: bold;
        color: #656565;
        font-size: 1rem;
        .van-row{padding-bottom: 20px;}
        .fa{padding-right: 12px;font-size: 1.2rem;color: #0957ff;width: 20px;height: 20px;}
        .fa-big{font-size: 1.3rem;}
    }
}
</style>